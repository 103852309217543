.ck-source-editing-area textarea {
  position: static;
  height: 400px;
  overflow: scroll;
}

.custom-block-indent-a {
  margin-left: 10%;
}

.custom-block-indent-b {
  margin-left: 20%;
}

.custom-block-indent-c {
  margin-left: 30%;
}

.ck.ck-content h3.category {
  font-family: 'Bebas Neue';
  font-size: 20px;
  font-weight: bold;
  color: #d1d1d1;
  letter-spacing: 10px;
  margin: 0;
  padding: 0;
}

.ck.ck-content p.info-box {
  padding: 1.2em 2em;
  border: 1px solid #e91e63;
  border-left: 10px solid #e91e63;
  border-radius: 5px;
  margin: 1.5em;
}
.ck-editor__editable_inline {
  min-height: 400px;
}

#editor table, img {
  margin: auto;
}

#editor > table, th, td
{
  border: 1px solid black;
}
