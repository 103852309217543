.class-actived {
  background-color: rgba(75, 193, 191, 1);
}
.checkbox-select {
	width: 20px;
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FF0000;
}

.is-disable {
  pointer-events: none;
  opacity: 0.7;
  cursor: 'not-allowed';
}